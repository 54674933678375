.forgotPasswordContainer {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(231, 231, 231); */
    position: fixed;
    top: 0%;
    left: 0;
  }
  .forgotPasswordBox {
    backdrop-filter: blur(250px);
    border: 1px solid black;
    width: 25vw;
    height: 40vh;
    box-sizing: border-box;
    overflow: hidden;
  }
  .forgotPasswordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 2vmax;
    justify-content: space-evenly;
    height: 70%;
    transition: all 0.5s;
  }

  .forgotPasswordHeading {
    text-align: center;
    color: rgba(0, 0, 0, 0.664);
    font: 400 1.3vmax "Roboto";
    padding: 1.3vmax;
    border-bottom: 1px solid rgba(0, 0, 0, 0.205);
    width: 50%;
    margin: auto;
  }
  
  .forgotPasswordForm > div {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .forgotPasswordForm > div > input {
    padding: 1vmax 4vmax;
    padding-right: 1vmax;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.267);
    border-radius: 4px;
    font: 300 0.9vmax cursive;
    outline: none;
  }
  .forgotPasswordForm > div > svg {
    position: absolute;
    transform: translateX(1vmax);
    font-size: 1.6vmax;
    color: rgb(218 31 31 / 62%);
  }
  
  .forgotPasswordBtn {
    border: none;
    background-color: tomato;
    color: white;
    font: 300 0.9vmax "Roboto";
    width: 100%;
    padding: 0.8vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
  }
  .forgotPasswordBtn:hover {
    background-color: rgb(179, 66, 46);
  }
  
  /* This is responsive for phone , I have to do responsive for device width have more than 600 px and less than 900 px
  it is not a big deal I have to just change the properties of same classes. */
    @media screen and (max-width: 600px) {
      .forgotPasswordContainer {
        background-color: white;
      }
      .forgotPasswordBox {
        width: 100vw;
        height: 95vh;
      }
      .forgotPasswordForm {
        padding: 5vmax;
      }
      .forgotPasswordForm > div > input {
        padding: 2.5vmax 5vmax;
        font: 300 1.7vmax cursive;
      }
      .forgotPasswordForm > div > svg {
        font-size: 2.8vmax;
      }

      .forgotPasswordBtn {
        font: 300 1.9vmax "Roboto";
        padding: 1.8vmax;
      }
    }